<template>
  <main class="project">
    <ProjectHead />
    <form @submit.prevent.stop="formSubmit" class="project_search">
      <div class="project_search_wrap">
        <div class="select">
          <div class="select_box select_w_130">
            <select
              name="basic"
              id="basic"
              class="basic"
              v-model="order_by_price_dt"
            >
              <option value="" selected>기본구분</option>
              <option value="project_max_price">금액 높은 순</option>
              <option value="project_min_price">금액 낮은 순</option>
              <option value="reg_dt_tm">최신 등록 순</option>
              <option value="request_expiration_dt">마감 임박 순</option>
            </select>
          </div>

          <div class="select_box select_w_178">
            <select
              name="system"
              id="system"
              class="system"
              v-model="search_progress"
            >
              <option value="" selected>프로젝트 진행방식</option>
              <option value="1">상주(파견)희망</option>
              <option value="2">도급 희망</option>
              <option value="0">기타</option>
            </select>
          </div>

          <div class="select_box select_w_130">
            <select name="field" id="field" v-model="search_work">
              <option value="" selected>업무분야</option>
              <option v-for="(work, idx) in works" :key="idx" :value="work.cd">
                {{ work.nm }}
              </option>
            </select>
          </div>

          <div class="select_box select_w_130">
            <select name="details" id="details" v-model="search_task">
              <option value="" selected>세부분야</option>
              <option v-for="(task, idx) in tasks" :key="idx" :value="task.cd">
                {{ task.nm }}
              </option>
            </select>
          </div>

          <div class="select_box select_w_130">
            <select name="city" id="city" v-model="search_area">
              <option value="" selected>지역구분</option>
              <option v-for="(area, idx) in areas" :key="idx" :value="area.cd">
                {{ area.nm }}
              </option>
            </select>
          </div>

          <div class="select_box select_w_130">
            <select name="division" id="division" v-model="search_recruit">
              <option value="" disabled selected>모집구분</option>
              <option value="1">신규</option>
              <option value="2">모집 중</option>
              <option value="3">모집 완료</option>
            </select>
          </div>
        </div>
        <ul>
          <li>
            <p>프로젝트 금액</p>
            <div class="select_box select_w_130">
              <select
                name="price_min"
                id="price_min"
                v-model="search_min_price"
              >
                <option value="" disabled selected>이상</option>
                <option value="50">50만원</option>
                <option value="100">100만원</option>
                <option value="500">500만원</option>
                <option value="1,000">1,000만원</option>
                <option value="2,000">2,000만원</option>
                <option value="3,000">3,000만원</option>
                <option value="4,000">4,000만원</option>
                <option value="5,000">5,000만원</option>
              </select>
            </div>

            ~
            <div class="select_box select_w_130">
              <select
                name="price_max"
                id="price_max"
                v-model="search_max_price"
              >
                <option value="" disabled selected>이하</option>
                <option value="50">50만원</option>
                <option value="100">100만원</option>
                <option value="500">500만원</option>
                <option value="1,000">1,000만원</option>
                <option value="2,000">2,000만원</option>
                <option value="3,000">3,000만원</option>
                <option value="4,000">4,000만원</option>
                <option value="5,000">5,000만원</option>
                <option value="10,000 ">10,000만원</option>
                <option value="15,000 ">15,000만원</option>
                <option value="20,000 ">20,000만원</option>
                <option value="30,000">30,000만원</option>
              </select>
            </div>
          </li>
          <li>
            <p>프로젝트 기간</p>
            <div class="select_box select_w_130">
              <select name="date_min" id="date_min" v-model="search_min_period">
                <option value="" disabled selected>이상</option>
                <option value="7">7일</option>
                <option value="15">15일</option>
                <option value="30">30일</option>
                <option value="60">60일</option>
                <option value="90">90일</option>
                <option value="120">120일</option>
                <option value="150">150일</option>
                <option value="180">180일</option>
                <option value="365">1년</option>
              </select>
            </div>

            ~
            <div class="select_box select_w_130">
              <select name="date_max" id="date_max" v-model="search_max_period">
                <option value="" disabled selected>이하</option>
                <option value="7">7일</option>
                <option value="15">15일</option>
                <option value="30">30일</option>
                <option value="60">60일</option>
                <option value="90">90일</option>
                <option value="120">120일</option>
                <option value="150">150일</option>
                <option value="180">180일</option>
                <option value="365">1년</option>
                <option value="730">2년</option>
                <option value="1095">3년</option>
              </select>
            </div>

            <input
              type="text"
              placeholder="프로젝트 명 입력"
              class="project_name_input"
              v-model="search_project"
            />
          </li>
        </ul>
        <div class="left_btn">
          <button type="submit">검색</button>
        </div>
      </div>
    </form>
    <div class="project_result">
      <div
        v-if="projects.length == 0"
        class="project_result_list"
        align="center"
      >
        검색 결과가 없습니다.
      </div>
      <div
        v-for="(project, index) in projects"
        :key="index"
        class="project_result_list"
        @click="goDetail(project.projectCd)"
        v-else
      >
        <div class="project_result_heading">
          <div class="project_tags">
            <div class="tag_contract">
              <span v-if="project.progress == 1">상주(파견) 희망</span>
              <span v-if="project.progress == 2">도급 희망</span>
              <span v-if="project.progress == 0">기타</span>
            </div>
            <div class="tag_etc">
              <span v-if="project.work">{{ project.work.nm }}</span>
            </div>
            <div class="tag_new">
              <span v-if="project.task">{{ project.task.nm }}</span>
            </div>

            <div class="tag_recruiting">
              <span v-if="project.recruit == 1" :value="project.recruit"
                >신규</span
              >
              <span v-if="project.recruit == 2" :value="project.recruit"
                >모집 중</span
              >
              <span
                class="tag_recruit_end"
                v-if="project.recruit == 3"
                :value="project.recruit"
                >모집 완료</span
              >
            </div>
          </div>
          <div class="project_reg_date">
            <span>등록일 : {{ project.regDtTm }}</span>
          </div>
        </div>
        <h3>{{ project.projectNm }}</h3>
        <p class="project_result_division">
          <span v-if="project.work">{{ project.work.nm }}</span> |
          <span v-if="project.task">{{ project.task.nm }}</span> |
          <span>{{ project.state }}</span>
        </p>
        <div class="project_result_detail">
          <p>
            프로젝트 예산범위
            <span>{{ project.projectMinPrice | comma }}원</span> ~
            <span>{{ project.projectMaxPrice | comma }}원</span>
          </p>
          <p>
            프로젝트 기간
            <span class="prd_day">{{ project.projectPeriod }}일</span>
            모집 기간
            <span v-if="project.requestExpirationDt > 0">
              D - {{ project.requestExpirationDt }}
            </span>
            <span v-else>D - 0</span>
          </p>
        </div>
        <p class="project_result_description">
          {{ project.detailContent }}
        </p>
      </div>
      <!-- <b-pagination-nav
        :link-gen="linkGen"
        :number-of-pages="totalPages ? totalPages : 1"
        use-router
        v-model="currentPage"
        :limit="10"
        first-text="<<"
        prev-text="<"
        next-text=">"
        last-text=">>"
      ></b-pagination-nav> -->
      <base-pagination
        :total="totalItems"
        :loading="contentLoading"
        :currentPage="currentPage"
        :pageCount="totalPages"
        :perPage="pageSize"
        @change="read"
      />
    </div>
  </main>
</template>

<script>
import BasePagination from "@/components/BasePagination.vue";
import ProjectHead from "@/components/ProjectHead.vue";
import { mapGetters } from "vuex";
import client from "api-client";

export default {
  components: {
    ProjectHead,
    BasePagination,
  },
  data() {
    return {
      totalItems: 0,
      totalPages: 0,
      currentPage: 1,
      pageSize: 10,
      contentLoading: true,

      projects: [],
      works: [],
      tasks: [],
      areas: [],
      // currentPage: this.currentPage,
      // totalPages: 1,
      // pageSize: 10,
      order_by_price_dt: "",
      search_progress: "",
      search_work: "",
      search_task: "",
      search_area: "",
      search_recruit: "",
      search_min_price: "",
      search_max_price: "",
      search_min_period: "",
      search_max_period: "",
      search_project: "",
    };
  },
  methods: {
    formSubmit() {
      let params = this.getFormData();
      params.page = 1;
      let payloadString = Object.entries(params)
        .map((e) => e.join("="))
        .join("&");

      if (
        "/project?" + encodeURI(payloadString) !=
        this.$router.history.current.fullPath
      ) {
        this.$router.push({
          name: "Project",
          query: params,
        });
      }
    },
    goDetail(projectCd) {
      if (!this.isAuthenticated) {
        if (confirm("로그인이 필요합니다. 로그인을 하시겠습니까?")) {
          this.$router.push({
            name: "Login",
          });
        }
        return;
      } else {
        this.$router.push({
          name: "ProjectSeq",
          params: {
            seq: projectCd,
          },
        });
      }
    },
    async read(page = 1) {
      await client.codeList("WORK").then(
        (response) => {
          const { data } = response;
          this.works = data;
        },
        (error) => {
          console.log(error);
        }
      );

      await client.codeList("TASK").then(
        (response) => {
          const { data } = response;
          this.tasks = data;
        },
        (error) => {
          console.log(error);
        }
      );

      await client.codeList("AREA").then(
        (response) => {
          const { data } = response;
          this.areas = data;
        },
        (error) => {
          console.log(error);
        }
      );

      let params = this.getFormData(page);
      await client.projectList(params).then(
        (response) => {
          const { data } = response;
          this.projects = data.content;
          this.totalPages = data.totalPages;
          this.totalElements = data.totalElements;
          let today = this.$date().format("YYYY-MM-DD");
          this.projects.forEach(function (e) {
            let sdate = today.replace("-", "/");
            let edate = e.requestExpirationDt.replace("-", "/");
            let t_sdate = new Date(sdate);
            let t_edate = new Date(edate);
            let elapsedMSec = t_edate.getTime() - t_sdate.getTime();
            let elapsedDay = elapsedMSec / 1000 / 60 / 60 / 24;
            let term = elapsedDay;
            e.requestExpirationDt = term;
          });
        },
        (error) => {
          console.log(error);
        }
      );
    },
    getFormData(page) {
      let params = {};
      params.size = this.pageSize;
      if (this.order_by_price_dt != null && this.order_by_price_dt != "") {
        params.order_by_price_dt = this.order_by_price_dt;
      }
      if (this.search_progress != null && this.search_progress != "") {
        params.search_progress = this.search_progress;
      }
      if (this.search_work != null && this.search_work != "") {
        params.search_work = this.search_work;
      }
      if (this.search_task != null && this.search_task != "") {
        params.search_task = this.search_task;
      }
      if (this.search_area != null && this.search_area != "") {
        params.search_area = this.search_area;
      }
      if (this.search_recruit != null && this.search_recruit != "") {
        params.search_recruit = this.search_recruit;
      }
      if (this.search_min_price != null && this.search_min_price != "") {
        params.search_min_price = this.search_min_price;
      }
      if (this.search_max_price != null && this.search_max_price != "") {
        params.search_max_price = this.search_max_price;
      }
      if (this.search_min_period != null && this.search_min_period != "") {
        params.search_min_period = this.search_min_period;
      }
      if (this.search_max_period != null && this.search_max_period != "") {
        params.search_max_period = this.search_max_period;
      }
      if (this.search_project.length != 0) {
        params.search_project = this.search_project;
      }
      this.currentPage = page;
      params.page = this.currentPage;

      console.log("check!!", params);
      return params;
    },
    // linkGen(pageNum) {
    //   let params = this.getFormData();
    //   params.page = pageNum;
    //   let payloadString = Object.entries(params)
    //     .map((e) => e.join("="))
    //     .join("&");

    //   console.log("테스트 : ", `?${payloadString}`);

    //   return `?${payloadString}`;
    // },
  },
  created() {
    if (this.$route.query) {
      this.order_by_price_dt = this.$route.query.order_by_price_dt
        ? this.$route.query.order_by_price_dt
        : "";
      this.search_progress = this.$route.query.search_progress
        ? this.$route.query.search_progress
        : "";
      this.search_work = this.$route.query.search_work
        ? this.$route.query.search_work
        : "";
      this.search_task = this.$route.query.search_task
        ? this.$route.query.search_task
        : "";
      this.search_area = this.$route.query.search_area
        ? this.$route.query.search_area
        : "";
      this.search_recruit = this.$route.query.search_recruit
        ? this.$route.query.search_recruit
        : "";
      this.search_min_price = this.$route.query.search_min_price
        ? this.$route.query.search_min_price
        : "";
      this.search_max_price = this.$route.query.search_max_price
        ? this.$route.query.search_max_price
        : "";
      this.search_min_period = this.$route.query.search_min_period
        ? this.$route.query.search_min_period
        : "";
      this.search_max_period = this.$route.query.search_max_period
        ? this.$route.query.search_max_period
        : "";
      this.search_project = this.$route.query.search_project
        ? this.$route.query.search_project
        : "";
    }
    this.read();
  },
  computed: {
    ...mapGetters(["isAuthenticated"]),
  },
  filters: {
    comma(val) {
      return String(val).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
  },
};
</script>
